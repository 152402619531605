<template>
  <div class="table-head">
    <a-form-model class="form-appModal" ref="promoAppsForm" :model="form">
      <div class="d-flex flex-column">
        <div class="float-right">
          <p class="font-size-16 font-weight-light">Select application</p>
        </div>
        <a-table :pagination="false" :row-key="(record) => record.application_id" :row-selection="rowSelection" :columns="appsColumns" :data-source="filteredApps">
          <template slot="app" slot-scope="app, record">
            <div class="d-flex align-items-center">
              <a-icon v-if="record.platform.toLowerCase() === 'android'" type="android" class="text-success" />
              <a-icon v-if="record.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
              <a-icon v-if="record.platform.toLowerCase() === 'web'" type="global" class="text-default" />
              <span class="ml-2">{{ record.name }}</span>
            </div>
          </template>
          <template slot="bundle" slot-scope="bundle, record">
            <span>
              {{ record.bundle_name }}
            </span>
          </template>
          <template slot="min_app_version" slot-scope="min_app_version, record">
            <a-input v-model="record.min_app_version" placeholder="0.0.0" />
          </template>
          <template slot="max_app_version" slot-scope="max_app_version, record">
            <div class="d-flex align-items-center">
              <a-input v-model="record.max_app_version" placeholder="999.9" class="mr-2" />
              <div class="clear app" @click="clearApp(record)">
                <a-icon type="delete" class="text-red" />
              </div>
            </div>
          </template>
        </a-table>
        <div v-if="!this.isFormEmpty" class="invalidApps">
          <p>Please select at least one application</p>
        </div>
        <div v-if="!this.isWebAndOther" class="invalidApps">
          <p>You can't select both web and other types of apps. Please select only web or android / ios</p>
        </div>
      </div>

      <a-row class="footer-viewscreen">
        <a-col class="text-right" :span="24">
          <a-button @click="closeModal"> Cancel </a-button>
          <a-button v-if="modalAppsData" :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
            Save
          </a-button>
          <a-button v-else :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
            Add
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
const appsColumns = [
  {
    title: 'App',
    dataIndex: 'app',
    scopedSlots: { customRender: 'app' },
    customHeaderCell: (column) => ({
      class: 'table-header',
    }),
  },
  {
    title: 'Bundle ID',
    dataIndex: 'bundle',
    scopedSlots: { customRender: 'bundle' },
    customHeaderCell: (column) => ({
      class: 'table-header',
    }),
  },
  {
    title: 'Min. ver.',
    dataIndex: 'min_app_version',
    scopedSlots: { customRender: 'min_app_version' },
    customHeaderCell: (column) => ({
      class: 'table-header',
    }),
  },
  {
    title: 'Max. ver.',
    dataIndex: 'max_app_version',
    scopedSlots: { customRender: 'max_app_version' },
    customHeaderCell: (column) => ({
      class: 'table-header',
    }),
  },
]

export default {
  props: ['modalAppsData'],
  data: () => ({
    submitLoading: false,
    deleteLoading: false,
    form: {
      applications: [],
      minVersion: '',
      maxVersion: '',
    },
    apps: [],
    webAppId: 0,
    appsColumns,
    selectedRowKeys: [],
    selectedData: [],
    isFormEmpty: true,
    isWebAndOther: true,
  }),
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: false,
          },
        }),
      }
    },
    filteredApps() {
      const selectedKeysLength = this.selectedRowKeys.length
      const webAppIsChecked = this.selectedRowKeys.includes(this.webAppId)

      if (!selectedKeysLength || (webAppIsChecked && selectedKeysLength !== 1)) {
        return this.apps
      }
      return webAppIsChecked ? this.apps.filter(app => app.application_id === this.webAppId) : this.apps.filter(app => app.application_id !== this.webAppId)
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedData = this.getSelectedData()
      this.isFormEmpty = true
      this.isWebAndOther = true
    },
    getSelectedData() {
      return this.apps
        .filter(app => this.selectedRowKeys.includes(app.application_id))
    },
    appDataFilling() {
      this.selectedRowKeys = this.modalAppsData.map(app => app.application_id)

      this.selectedRowKeys.forEach(applicationId => {
        const matchedApp = this.modalAppsData.find(app => app.application_id === applicationId)
        if (matchedApp) {
          const appIndex = this.apps.findIndex(app => app.application_id === applicationId)
          if (appIndex !== -1) {
            this.apps[appIndex] = matchedApp
          }
        }
      })
      this.onSelectChange(this.selectedRowKeys)
    },
    onSubmit() {
      if (!this.selectedRowKeys.length) {
        this.isFormEmpty = false
        return false
      }
      if (this.selectedRowKeys.includes(53) && this.selectedRowKeys.length !== 1) {
        this.isWebAndOther = false
        return false
      }
      this.$refs.promoAppsForm.validate((valid) => {
        if (valid) {
          this.isFormEmpty = true
          this.$emit('selectApps', this.selectedData)
        } else {
          return false
        }
      })
    },
    clearApp(app) {
      app.max_app_version = ''
      app.min_app_version = ''
    },
    closeModal() {
      this.$emit('close')
    },
    async fetchApplications() {
      this.appsLoading = true
      try {
        const url = '/admin/applications'
        const response = await apiClient.get(url)
        if (response.data.data) {
          this.apps = response.data.data
          this.webAppId = (this.apps.find(app => app.platform === 'web')).application_id
        }
      } finally {
        this.appsLoading = false
      }
    },
  },
  async mounted() {
    await this.fetchApplications()
    if (this.modalAppsData) {
      this.appDataFilling()
    }
  },
}
</script>

<style lang="scss">
.footer-viewscreen {
  margin-left: -24px !important;
  margin-right: -24px !important;
  border-top: 1px solid #d9d9d9;
  padding: 10px 24px 0 24px !important;
}

.table-head .ant-table-thead>tr>th {
  background-color: #fafafa !important;
  font-weight: 600 !important;
}

.disabled-row {
  background-color: #fafafa;
  color: #595959;
  font-weight: 400;
}

.form-appModal {
  .ant-select-selection {
    background-color: transparent !important;
    border: 0 !important;
  }
}

.checkbox-list {
  display: flex !important;
  flex-wrap: wrap;

  &__item {
    flex: 1 1 30%;
    max-width: 31%;
    padding: 5px 0 !important;
  }
}

.invalidApps {
  margin-top: 1rem;
  color: #f5222e;
  clear: both;
  min-height: 22px;
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>
